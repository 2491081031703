import React, { useState } from "react";
import {
  TriangleDownIcon,
  TriangleUpDownIcon,
} from "../../TransmitDashboard/menuItems";
import {
  Button,
  ButtonGroup,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { FaEllipsisVertical } from "react-icons/fa6";
import { useMutation } from "@apollo/client";
import { DELETE_DRAFT_CAMPAIGN } from "../GraphQL/Queries";

const CampaignTable = ({
  data,
  column,
  isPerPage,
  isDraftTable,
  columns,
  isClickable,
  isPerginate,
  hasMore,
  currentPage,
  totalPage,
  handleNextOrPrevPage,
  itemsPerPage,
  handlePerPageChange,
}) => {
  const [perPages, setPerPages] = useState(10);
  const [currentData, seCurrentData] = useState(data);
  const [isAscending, setIsAscending] = useState(false);

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteDraft] = useMutation(DELETE_DRAFT_CAMPAIGN)

  const campaignStatus = (campaignStatus) => {
    seCurrentData(data?.filter(({ status }) => status === campaignStatus))

  }

  const handleDeleteDraftCampaign = (id) => {
    deleteDraft({
      variables: {
        id
      }
    })
  }


  return (
    <div className="table-cont">
      <TableContainer
        className={`reuse-table ${currentData?.length === 0 ? "empty" : 0}`}
      >
        <Table
          variant="simple"
          padding={"4px 0px 4px 0px"}
          border={"0.5px solid #D5D5D5"}
          sx={{
            th: {
              fontFamily: "Telegraf",
              fontSize: "16px",
              lineHeight: "10px",
              fontWeight: "400",
            },
            td: {
              fontFamily: "Telegraf",
              fontSize: "16px",
              lineHeight: "10px",
              fontWeight: "400",
            },
          }}
        >
          <Thead fontSize={"16px"}>
            <Tr>
              {column.map(({ title, isDropDown, isFilter, options }, index) => {
                return (
                  <Th
                    borderBottom={"0.5px solid #D5D5D5"}
                    color={"#000"}
                    key={index}
                  >
                    {!isDropDown && !isFilter && (
                      <Flex textTransform={"capitalize"}> {title}</Flex>
                    )}
                    {isDropDown && (
                      <Popover
                        placement="bottom-start"
                        border="0px"
                        isOpen={isOpen}
                        onClose={onClose}
                      >
                        <PopoverTrigger>
                          <Flex
                            gap={1}
                            cursor={"pointer"}
                            onClick={onOpen}
                            className="status-txt"
                          >
                            {title} <TriangleDownIcon />
                          </Flex>
                        </PopoverTrigger>
                        <PopoverContent border="0.5px solid #868686" w="120px">
                          <VStack spacing="8px">
                            {options.map(({ value }, index) => {
                              return (
                                <Button
                                  p={0}
                                  m={0}
                                  h="100%"
                                  variant={"none"}
                                  fontWeight={400}
                                  width="100%"
                                  justifyContent="flex-start"
                                  _hover={{ bg: "#D9D9D9" }}
                                  padding={"8px 16px"}
                                  borderRadius={"none"}
                                  key={index}
                                  onClick={() => {
                                    campaignStatus(value);
                                    onClose();
                                  }}
                                >
                                  {value}
                                </Button>
                              );
                            })}
                          </VStack>
                        </PopoverContent>
                      </Popover>
                    )}{" "}
                    {isFilter && (
                      <Flex
                        alignItems={"center"}
                        cursor={"pointer"}
                        textTransform={"capitalize"}
                      >
                        {title} <TriangleUpDownIcon />
                      </Flex>
                    )}

                  </Th>
                );
              })}
              {isDraftTable && (
                <>
                  <Th textTransform={"capitalize"} fontWeight={400} color={"#000"}>Action</Th>
                  <Th></Th>
                </>
              )}

            </Tr>
          </Thead>
          <Tbody>
            {currentData?.length !== 0 ? (
              currentData?.map((eachData) => (
                <Tr
                  key={eachData.id}
                  style={{ borderBottom: "0.5px solid #D5D5D5", color: "#000" }}
                  className={`${isClickable === true ? "table-row " : ""}`}
                >
                  {columns.map((column) => (
                    <Td
                      key={column.key}
                      onClick={() => {
                        if (isClickable === true) {
                          navigate(
                            `/dashboard/station/booking/${eachData.id}`
                          );
                        }
                      }}
                    >
                      {eachData[column.key]}
                    </Td>
                  ))}
                  {isDraftTable && (
                    <>
                      <Td color={"#F1613F"}>
                        <Link
                          to={`/book-campaign/checkout/${eachData.id}`}
                          className={{ color: "#F1613F", cursor: "pointer" }}
                        >
                          Checkout
                        </Link>
                      </Td>
                      <Td>
                        <Popover>
                          <PopoverTrigger>
                            <Button outline={'none'} bg={'transparent'}><FaEllipsisVertical /> </Button>
                          </PopoverTrigger>
                          <PopoverContent w="120px">
                            <Stack spacing={2}>
                              <Text px={4} py={2} cursor={'pointer'} _hover={{ bg: '#f1613f', color: '#fff', borderRadius: '4px' }}>EDIT</Text>
                              <Text px={4} py={2} cursor={'pointer'} _hover={{ bg: '#f1613f', color: '#fff', borderRadius: '4px' }} onClick={() => handleDeleteDraftCampaign(eachData.id)}>DELETE</Text>
                            </Stack>
                          </PopoverContent>
                        </Popover>
                      </Td>
                    </>
                  )}
                </Tr>
              ))
            ) : (
              <div className="no-data">No Active Campaigns</div>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {isPerginate && data?.length !== 0 && (
        <Flex alignItems={"center"} fontSize={"14px"} mt={"30px"}>
          <Text
            width={"32px"}
            height={"32px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            border={"0.5px solid #D9D9D9"}
          >
            {currentPage}
          </Text>{" "}
          <span className="lower-text" style={{ paddingLeft: "8px" }}>
            of
          </span>{" "}
          <Text padding={"10px 8px 10px 10px"}>
            {Math.round(totalPage)}
            {/* {totalPage} */}
          </Text>
          <Flex>
            <ButtonGroup>
              <Button
                onClick={() => handleNextOrPrevPage(currentPage - 1)}
                minW={"24px"}
                p={0}
                m={0}
                variant={"none"}
                isDisabled={currentPage === 1}
              >
                <ChevronLeftIcon boxSize={"24px"} />{" "}
              </Button>
              <Button
                onClick={() => handleNextOrPrevPage(currentPage + 1)}
                minW={"24px"}
                p={0}
                m={0}
                variant={"none"}
                css={{ marginInlineStart: "0 !important" }}
                isDisabled={!hasMore}
              >
                <ChevronRightIcon boxSize={"24px"} />
              </Button>
            </ButtonGroup>
          </Flex>
          {isPerPage && (
            <Flex padding={"0px 8px"} className="perpages">
              <span>Entries per page</span>
              <select
                onChange={(e) => handlePerPageChange(e)}
                value={itemsPerPage}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </Flex>
          )}
        </Flex>
      )}
    </div>
  );
};

export default CampaignTable;
