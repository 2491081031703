import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Table, Thead, Tbody, Th, Td, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@apollo/client";

//Mutation Query
import { GET_ADVERTISER_PORTFOLIOS } from "../../../../../../components/GraphQL/Queries";

import { TableSpinner } from "../../../../../../components/Spinner";
import { EmptyState } from "../../../../../../components/Modal/ScheduleModal";
import moment from "moment/moment";

import { MdMoreHoriz } from "react-icons/md";
import { DELETE_PORTFOLIO } from "../../../../../../components/GraphQL/Mutation";
import { useRef } from "react";

const AnalyticTable = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const ref=useRef()

  const [isOpen, setIsOpen] = useState(false);

  const { loading, data } = useQuery(GET_ADVERTISER_PORTFOLIOS);
  const [deletePortfolio, {loading: deleteLoading}] = useMutation(DELETE_PORTFOLIO);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOpen]);

  const deletePortfolioHandler = (portfolioId) => {
    deletePortfolio({
      variables: {
        portfolioId: portfolioId,
      },
      refetchQueries: [{ query: GET_ADVERTISER_PORTFOLIOS }],
      onCompleted: () => {
        toast({
          title: `portfolio deleted`,
          status: "success",
          position: "top-right",
          isClosable: true,
        });
      },
    });
  };



  return (
    <>
      {data?.advertiser.getAllPortfolio.length === 0 && (
        <EmptyState
          title="You do not have an analytics portfolio"
          text="Create a new portfolio in order to view your campaign analytics"
          btnText="Create Analytics Portfolio"
          route="/analytics/create-portfolio"
        />
      )}
      {loading ? (
        <TableSpinner />
      ) : (
        data?.advertiser.getAllPortfolio.length && (
          <TableContainer ref={ref}>
            <ButtonContainer>
              <Button onClick={() => navigate("/analytics/create-portfolio")}>
                New Portfolio
              </Button>
            </ButtonContainer>
            <TableWrapper>
              <Table>
                <Thead>
                  <TableHeaderRow>
                    <Th>PORTFOLIO</Th>
                    <Th>PROPERTY ID</Th>
                    <Th>METRIC</Th>
                    <Th>campaign Name</Th>
                    <Th>Created At</Th>
                    <Th></Th>
                  </TableHeaderRow>
                </Thead>
                <Tbody>
                  {data?.advertiser.getAllPortfolio.map(
                    ({
                      propertyId,
                      metric,
                      title,
                      id,
                      campaignName,
                      campaignId,
                      createdAt,
                    }) => {
                      return (
                        <TableBodyRow>
                          <Link to={`/analytics/portfolio/${id}`}>
                            <Td color="#F1613F">{title}</Td>
                          </Link>
                          <Td>{propertyId}</Td>
                          <Td>{metric}</Td>
                          <Td>{campaignName}</Td>
                          <Td>{moment(createdAt).format("LL")}</Td>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <button
                              style={{
                                width: "24px",
                                height: "24px",
                                borderRadius: "50%",
                                background: "#F1613F",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                              }}
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              <MdMoreHoriz />
                            </button>
                            {isOpen && (
                              <DropdownWrapper>
                                <DropdownItem
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/station/booking/${campaignId}`
                                    )
                                  }
                                >
                                  view campaign
                                </DropdownItem>
                                <DropdownItem
                                onClick={()=> navigate(`/analytics/portfolio/${id}`)}
                                >
                                  view portfolio
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => deletePortfolioHandler(id)}
                                >
                                  {deleteLoading ? "deleting...": "delete"}
                                </DropdownItem>
                              </DropdownWrapper>
                            )}
                          </div>
                        </TableBodyRow>
                      );
                    }
                  )}
                </Tbody>
              </Table>
            </TableWrapper>
          </TableContainer>
        )
      )}
    </>
  );
};

export default AnalyticTable;

const TableContainer = styled.div`
  padding-top: 50px;
  padding-left: 65px;
  padding-right: 30px;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  margin-bottom: 50px;
`;
const Button = styled.button`
  width: 180px;
  height: 40px;
  background: #F1613F;
  opacity: 0.9;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border-radius: 1.50209px;
  &:hover {
    opacity: 1;
  }
`;
const TableWrapper = styled.div`
  max-height: 500px;
  border: 1px solid #e8e8e8;
`;
const TableHeaderRow = styled.tr`
  background: #eff6f4;
  color: #004643;
  white-space: nowrap;
  & > th {
    letter-spacing: 0.05em;
    line-height: 20px;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
  }
`;
const TableBodyRow = styled.tr`
  color: #004643;
  white-space: nowrap;
  & > td {
    letter-spacing: 0.05em;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    & span {
      background: #dff9dc;
      border-radius: 15px;
      padding: 5px 20px;
    }
  }
`;

const DropdownWrapper = styled.div`
  position: absolute;
  width: 150px;
  left: -70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0;
  gap: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(9, 11, 23, 0.1),
    0px 8px 24px rgba(9, 11, 23, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  z-index: 999999;
`;
const DropdownItem = styled.span`
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 10px;
  &:hover {
    background: #e6e6e6;
  }
`;
