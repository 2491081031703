import React, {useState} from "react";
import "./style.css";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { TableSpinner } from "../../Spinner";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import styled from "styled-components";
import { useFormik } from "formik";
import { AiOutlineDelete } from "react-icons/ai";
import { useMutation } from "@apollo/client";
import { DELETE_VOLUME_DISCOUNT } from "../../GraphQL/Mutation";

const DiscountRate = ({
  createDiscount,
  getDiscount,
  setInventoryValues,
  getDiscountLoad,
  userId,
  refetch
}) => {
  const [discountId, setDiscountId] = useState(null)
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteDiscount] = useMutation(DELETE_VOLUME_DISCOUNT)

  const initialValues = {
    discount: getDiscount.startingDiscount,
    fromAmount: getDiscount.startingPrice,
    toAmount: 0,
  };

  const validateDiscount = (value) => {
    return Math.min(80, Math.max(0, parseInt(value, 10)));
  };

  const validateAmount = (value) => {
    return Math.max(0, parseInt(value, 10));
  };

  const handleFormSubmit = (values, { resetForm }) => {
    createDiscount({
      variables: {
        input: { ...values, broadcasterId: userId },
      },
      onCompleted: () => {
        toast({
          title: "Discount created.",
          description: "We've created your discount for you.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
      onError: ({ networkError }) => {
        toast({
          title: "Error creating Discount.",
          description: `${networkError?.result?.errors[0]?.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    });
    resetForm();
  };

  const {
    handleSubmit,
    handleChange: handleFormInputChange,
    values,
  } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    let sanitizedValue;

    // Apply validation based on the input field name
    switch (name) {
      case "fromAmount":
      case "toAmount":
        sanitizedValue = validateAmount(value);
        break;
      case "discount":
        sanitizedValue = validateDiscount(value);
        break;
      default:
        sanitizedValue = value;
    }

    // Update the form state with the sanitized value
    handleFormInputChange({
      ...event,
      target: {
        ...event.target,
        name: name,
        value: sanitizedValue,
      },
    });
  };

  const deleteDiscountHandler = (id) => {
    deleteDiscount({
      variables: {
        deleteVolumeDiscountId: id
      },
      onCompleted: () => {
        toast({
          title: "Discount deleted.",
          description: "We've deleted your discount for you.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose()
        refetch()
      },
      onError: () => {
        toast({
          title: "Error deleting Discount, Try Again",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        onClose()
      }
    })
  }



  return (
    <>
      <div className="station-input-wrapper">
        <Accordion allowMultiple>
          <AccordionItem maxW="666px">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  h="52px"
                  bg="#E4F0ED"
                  _hover={{ background: "#E4F0ED" }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    <VolDiscountText>Add Volume Discounts</VolDiscountText>
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="12px" />
                  ) : (
                    <AddIcon fontSize="12px" />
                  )}
                </AccordionButton>

                <AccordionPanel pb={4}>
                  {/* <form onSubmit={handleSubmit}>
              <VStack>
                <div className="station-second-wrapper">
                  <div className="station-select-wrapper">
                    <div className="select-wrapper">
                      <label htmlFor="fromAmount">
                        <SpotAccordionText>from</SpotAccordionText>
                      </label>
                      <Input
                        type="number"
                        mt="20px"
                        id="fromAmount"
                        name="fromAmount"
                        onChange={handleAmountChange}
                        value={values.fromAmount}
                        min={0}
                      />
                    </div>
                    <div className="select-wrapper">
                      <label htmlFor="starting-spot">
                        <SpotAccordionText>to</SpotAccordionText>
                      </label>
                      <Input
                        type="number"
                        mt="20px"
                        id="toAmount"
                        name="toAmount"
                        onChange={handleAmountChange}
                        value={values.toAmount}
                        min={0}
                      />
                    </div>
                  </div>
                  <div>
                    <label>
                      {" "}
                      <SpotAccordionText>
                        Discount in %{" "}
                      </SpotAccordionText>
                    </label>
                    <Input
                      type="number"
                      mt="20px"
                      id="discount"
                      name="discount"
                      onChange={handleDiscountChange}
                      value={values.discount}
                      min={0}
                      max={80}
                    />
                  </div>
                </div>
                <Center>
                  <Button
                    bg="#f99b28"
                    borderRadius="2px 2px 0px 0px"
                    h="44px"
                    w="210px"
                    color="white"
                    isLoading={volDiscountLoad}
                    loadingText="Creating ..."
                    _hover={{ bg: "" }}
                    type="submit"
                    isDisabled={
                      !values.discount ||
                      !values.toAmount ||
                      (values.fromAmount < 0)
                    }
                  >
                    Add
                  </Button>
                </Center>
              </VStack>
            </form> */}

                  <form onSubmit={handleSubmit}>
                    <VStack>
                      <div className="station-second-wrapper">
                        <div className="station-select-wrapper">
                          <div className="select-wrapper">
                            <label htmlFor="fromAmount">
                              <SpotAccordionText>from</SpotAccordionText>
                            </label>
                            <Input
                              type="number"
                              mt="20px"
                              id="fromAmount"
                              name="fromAmount"
                              onChange={handleInputChange}
                              value={values.fromAmount}
                              min={0}
                            />
                          </div>
                          <div className="select-wrapper">
                            <label htmlFor="starting-spot">
                              <SpotAccordionText>to</SpotAccordionText>
                            </label>
                            <Input
                              type="number"
                              mt="20px"
                              id="toAmount"
                              name="toAmount"
                              onChange={handleInputChange}
                              value={values.toAmount}
                              min={0}
                            />
                          </div>
                        </div>
                        <div>
                          <label>
                            <SpotAccordionText>Discount in %</SpotAccordionText>
                          </label>
                          <Input
                            type="number"
                            mt="20px"
                            id="discount"
                            name="discount"
                            onChange={handleInputChange}
                            value={values.discount}
                            min={0}
                            max={80}
                          />
                        </div>
                      </div>
                      <Center>
                        <Button
                          bg="#f99b28"
                          borderRadius="2px 2px 0px 0px"
                          h="44px"
                          w="210px"
                          color="white"
                          //   isLoading={volDiscountLoad}
                          loadingText="Creating ..."
                          _hover={{ bg: "" }}
                          type="submit"
                          isDisabled={
                            !values.discount ||
                            !values.toAmount ||
                            values.fromAmount < 0
                          }
                        >
                          Add
                        </Button>
                      </Center>
                    </VStack>
                  </form>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </div>
      <div className="table-container">
        <ListDiscountText>list of discounts</ListDiscountText>

        <TableContainer className="table-body-container discount_table">
          {!getDiscountLoad ? (
            <Table variant="striped" colorScheme="brand" mt={10}>
              <Thead>
                <Tr>
                  <Th w="14px"></Th>
                  <Th>Amount</Th>
                  <Th>Discount %</Th>
                  <Th></Th>
                </Tr>
              </Thead>

              <Tbody className="table-body-container">
                {getDiscount.listOfDiscount.map(
                  ({ id= null, amount = "", discount = "" }) => {
                    return (
                      <Tr id={id}>
                        <Td w="14px"></Td>
                        <Td>{amount}</Td>
                        <Td>{discount}</Td>
                        <Td>
                          <Button
                            width={"200px"}
                            display={"flex"}
                            gap={2}
                            fontSize={"20px"}
                            onClick={()=>{
                              setDiscountId(id)
                              onOpen() 
                            }}
                            _hover={{bg: "transparent", }}
                          >
                            <AiOutlineDelete 
                            size={26} 
                            style={{
                              transition: "all 0.2s ease-in-out",
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.15)")}
                            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            />
                            {/* DELETE */}
                          </Button>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          ) : (
            <Center>
              <TableSpinner />
            </Center>
          )}
        </TableContainer>
      </div>
      {isOpen && <DeletDiscountModal isOpen={isOpen} onClose={onClose} discountId={discountId} deleteDiscountHandler={deleteDiscountHandler} />}
    </>
  );
};

export default DiscountRate;

const DeletDiscountModal = ({ isOpen, onClose, discountId, deleteDiscountHandler }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Discount</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={6} textAlign={'center'} fontSize={'20px'} fontWeight={600}>A<span className="lower-text">re you sure you want to delete this discount?</span></ModalBody>
        <ModalFooter justifyContent={'center'} mt={6}>
          <Button fontSize={"18px"} bg={'transparent'} _hover={{bg:  "#F1613F", color: "#fff"}} onClick={()=>deleteDiscountHandler(discountId)}>DELETE</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const VolDiscountText = styled.h2`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;

  color: #004643;
`;

const SpotAccordionText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #004643;
`;

const ListDiscountText = styled.p`
  font-family: "Adobe Clean";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #004643;
`;
