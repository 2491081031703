import { gql } from "@apollo/client";
import { gql as gqlreq } from "graphql-request";

export const GET_BROADCASTER_PROFILE = gql`
  query GetBroadcasterProfile {
    broadcaster {
      getBroadcasterProfile {
        tagLine
        banner
        phoneNumber
        radioStationDigitalStreaUrl
        radioStationCategory
        radioStationWebsite
        zipcode
        city
        state
        country
        address
        positionHeld
        fullname
        email
        name
      }
    }
  }
`;

export const BROADCASTER_SLOTS_GQL = gqlreq`
query GetBroadcasterSlots($broadcasterId: ID!, $currency: Currency) {
  guest {
    getBroadcasterSlots(broadcasterId: $broadcasterId, currency: $currency) {
      id
      broadcasterId
      Jingle15SecPrice
      Jingle30SecPrice
      Jingle45SecPrice
      Jingle60SecPrice
      Ann50WordsPrice
      Ann75WordsPrice
      Ann100WordsPrice
      startTime
      endTime
      currency
    }
  }
}
`;

export const GET_DISCOUNT = gql`
  query GetDiscount {
    broadcaster {
      getDiscount {
        startingPrice
        startingDiscount
        listOfDiscount {
          id
          amount
          discount
        }
      }
    }
  }
`;

export const USER_INFO = gql`
  query Me {
    auth {
      me {
        name
        id
        group
        fullname
        email
        isApproved
      }
    }
  }
`;
export const GET_WALLET_BALANCE = gql`
  query GetWalletBalance {
    broadcaster {
      getWalletBalance {
        pendingBalance
        currentBalance
      }
    }
  }
`;
export const GET_BROADCASTER_ACCOUNT_INFORMATION = gql`
  query GetBroadcasterAccountInformation {
    broadcaster {
      getBroadcasterAccountInformation {
        bankCode
        accountNumber
        accountName
        bankName
      }
    }
  }
`;
export const GET_PAYMENT_HISTORY = gql`
  query GetPaymentHistory(
    $page: page_Int_NotNull_min_1!
    $itemsPerPage: itemsPerPage_Int_NotNull_min_1!
  ) {
    broadcaster {
      getPaymentHistory(page: $page, itemsPerPage: $itemsPerPage) {
        transactions {
          id
          transactionDate
          amount
          paymentMethod
          status
        }
        totalCount
        page
        hasMore
      }
    }
  }
`;

export const GET_BROADCASTERS_REQ = gqlreq`
  query GetBroadcasters(
    $page: Int!
    $itemsPerPage: Int!
    $state: String
    $country: String
  ) {
    guest {
      getBroadcasters(
        page: $page
        itemsPerPage: $itemsPerPage
        state: $state
        country: $country
      ) {
        broadcasters {
          state
          startingPrice
          recommended
          name
          id
          country
          city
          banner
          isApproved
          tagLine
          discountedStatingPrice
        }
      }
    }
  }
`;

export const GET_BROADCASTERS = gql`
  query GetBroadcasters(
    $page: page_Int_NotNull_min_1!
    $itemsPerPage: itemsPerPage_Int_NotNull_min_1!
    $country: String
    $state: String
    $radioSationName: String
    $currency: Currency
  ) {
    guest {
      getBroadcasters(
        page: $page
        itemsPerPage: $itemsPerPage
        country: $country
        state: $state
        radioSationName: $radioSationName
        currency: $currency
      ) {
        broadcasters {
          id
          banner
          name
          startingPrice
          recommended
          country
          state
          city
          isApproved
          tagLine
          discountedStatingPrice
          discountPercentage
          currency
        }
        totalCount
        page
        hasMore
      }
    }
  }
`;

export const GET_CAMPAIGNNS = gql`
  query GetCampaigns {
    broadcaster {
      getCampaigns {
        id
        totalAmountSpent
        totalEarnings
        status
        date
      }
    }
  }
`;

export const GET_ALL_PROGRAM_SCHEDULE = gql`
  query GetAllProgramSchedule {
    broadcaster {
      getAllProgramSchedule {
        id
        startTime
        endTime
        show
        sponsor
        status
        weekDay
      }
    }
  }
`;

export const WALLET_BALANCE = gql`
  query GetWalletBalance {
    advertiser {
      getWalletBalance {
        pendingBalance
        currentBalance
      }
    }
  }
`;
export const TRANSAACTION_HISTORY = gql`
  query GetTransactionHistory(
    $page: page_Int_NotNull_min_1!
    $itemsPerPage: itemsPerPage_Int_NotNull_min_1!
    $sort: GetTransactionHistorySort
  ) {
    advertiser {
      getTransactionHistory(
        page: $page
        itemsPerPage: $itemsPerPage
        sort: $sort
      ) {
        transactions {
          id
          transactionDate
          transactionId
          type
          amount
          paymentMethod
          description
          status
        }
        totalCount
        page
        hasMore
      }
    }
  }
`;
export const GET_ADVERTISER_CAMPAIGNS = gql`
  query GetCampaigns(
    $page: page_Int_NotNull_min_1!
    $itemsPerPage: itemsPerPage_Int_NotNull_min_1!
    $broadcasterId: ID
    $startDate: Date
    $endDate: Date
  ) {
    advertiser {
      getCampaigns(
        page: $page
        itemsPerPage: $itemsPerPage
        broadcasterId: $broadcasterId
        startDate: $startDate
        endDate: $endDate
      ) {
        campaigns {
          id
          invoice
          campaignName
          companyName
          industryName
          date
          radioStation {
            id
            name
          }
          startDate
          endDate
          status
          amount
        }
        totalCount
        page
        hasMore
      }
    }
  }
`;

export const GET_RADIOSTATION_BOOKED_BY_ADVERTISER = gql`
  query GetRadioStationBookedByUser {
    advertiser {
      getRadioStationBookedByUser {
        id
        name
      }
    }
  }
`;

export const BROADCASTER_DASHBOARD_DATA = gql`
  query GetDashboardData {
    broadcaster {
      getDashboardData {
        activeCampaign
        pendingCampaign
        totalCampaign
        recentCampaigns {
          id
          campaignStatus
          campaignDetails
        }
      }
    }
  }
`;

export const BROADCASTER_SLOTS = gql`
  query GetBroadcasterSlots($broadcasterId: ID!) {
    guest {
      getBroadcasterSlots(broadcasterId: $broadcasterId) {
        startTime
        id
        endTime
        broadcasterId
        Jingle60SecPrice
        Jingle45SecPrice
        Jingle30SecPrice
        Jingle15SecPrice
        Ann75WordsPrice
        Ann50WordsPrice
        Ann100WordsPrice
      }
    }
  }
`;

export const BOOKING_STATS = gql`
  query GetBookingStats {
    advertiser {
      getBookingStats {
        totalNumberSlots
        totalNumberOfDays
        totalAmount
        numberOfradioStations
      }
    }
  }
`;

export const RESEND_VERIFICATION = gql`
  query GetResendVerification {
    auth {
      resendEmailVerificationCode
    }
  }
`;

export const ADVERTISER_DRAFTS = gql`
  query GetAllDraft(
    $page: page_Int_NotNull_min_1!
    $itemsPerPage: itemsPerPage_Int_NotNull_min_1!
    $date: Date
    $draftType: DraftType
  ) {
    advertiser {
      getAllDraft(
        page: $page
        itemsPerPage: $itemsPerPage
        date: $date
        draftType: $draftType
      ) {
        drafts {
          id
          date
          vendors
          type
          duration
          language
          slots
          subtotal
          radioStation {
            id
            name
          }
          advertType
        }
        totalCount
        page
        hasMore
      }
    }
  }
`;

export const DELETE_DRAFT_CAMPAIGN = gql`
  mutation DeleteDraft($input: deleteDraftInput) {
    advertiser {
      deleteDraft(input: $input)
    }
  }
`;

export const CAMPAIGN_SUMMARY = gql`
  query CampaignSummary {
    advertiser {
      getCampaignSummary {
        campaignSummary {
          id
          name
          price
        }
        grandTotal
        specialDiscount
        subTotal
        vat
      }
    }
  }
`;

export const TIME_SCHEDULE = gql`
  query GetBroadcasterTimeSchedule($broadcasterId: ID!) {
    guest {
      getBroadcasterTimeSchedule(broadcasterId: $broadcasterId) {
        weekDay
        status
        startTime
        sponsor
        show
        id
        endTime
      }
    }
  }
`;

export const GET_ALLSLOT = gql`
  query GetAllSlot {
    broadcaster {
      getAllSlot {
        startTime
        id
        endTime
        broadcasterId
        Jingle60SecPrice
        Jingle45SecPrice
        Jingle30SecPrice
        Jingle15SecPrice
        Ann75WordsPrice
        Ann50WordsPrice
        Ann100WordsPrice
      }
    }
  }
`;

export const GET_DRAFT = gql`
  query GetDraft($getDraftId: ID!) {
    advertiser {
      getDraft(id: $getDraftId) {
        subTotal
        id
        booking {
          slotId
          quantity
          language
          date
          broadcasterId
          advertType
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_SUMMARY_FROM_DRAFT = gql`
  query GetCampaignSummaryFromDraft($draftId: ID!, $currency: Currency) {
    advertiser {
      getCampaignSummaryFromDraft(draftId: $draftId, currency: $currency) {
        campaignSummary {
          id
          name
          price
          banner
        }
        subTotal
        vat
        totalDiscountApplied
        discountSummary {
          volumeDiscount {
            radioStationName
            discountPercentage
            discountAmount
          }
          specialAdvertiserDiscount {
            radioStationName
            discountPercentage
            discountAmount
          }
          subTotal
        }
        grandTotal
        currency
      }
    }
  }
`;

export const GET_CAMPAIGN_DETAILS = gql`
  query GetCampaignDetails($campaignId: ID!) {
    broadcaster {
      getCampaignDetails(campaignId: $campaignId) {
        id
        status
        startDate
        endDate
        advertType
        duration
        language
        numberOfDays
        numberOfSpots
        totalAmount
        links
        paymentMethod
        campaignDocument {
          name
          url
        }
        bookedSlots {
          startDate
          endDate
          slots {
            advertType
            date
            quantity
            language
            startTime
            endTime
          }
          bookingDetails {
            totalNoOfDays
            totalNoOfSpots
            totalNoOfSlots
          }
        }
      }
    }
  }
`;

export const ADVERTISER_CAMPAIGN_DETAILS = gql`
  query GetCampaignDetails($campaignId: ID!) {
    advertiser {
      getCampaignDetails(campaignId: $campaignId) {
        id
        startDate
        endDate
        campaignDocument {
          name
          url
        }
        radioStationDetails {
          id
          radioStation
          advertType
          duration
          language
          numberOfDays
          numberOfSpots
          totalAmount
          status
          paymentMethod
          bookedSlots {
            startDate
            endDate
            slots {
              advertType
              date
              quantity
              language
              startTime
              endTime
            }
            bookingDetails {
              totalNoOfDays
              totalNoOfSpots
              totalNoOfSlots
            }
          }
        }
      }
    }
  }
`;

export const GET_ADVERTISER_PORTFOLIOS = gql`
  query GetAllPortfolios {
    advertiser {
      getAllPortfolio {
        id
        title
        propertyId
        metric
        campaignId
        campaignName
        createdAt
      }
    }
  }
`;

export const GET_CAMPAIGNS_FOR_PORTFOLIOS = gql`
  query GetCampaignForPortfolio {
    advertiser {
      getCampaignForPortfolio {
        id
        name
      }
    }
  }
`;

export const GET_RADIOSTATION_FOR_PORTFOLIO = gql`
  query GetRadioStationsForPortfolio($portfolioId: ID!) {
    advertiser {
      getRadioStationsForPortfolio(portfolioId: $portfolioId) {
        radioStationId
        radioStationName
      }
    }
  }
`;

export const GET_PORTFOLIO_METRICS = gql`
  query GetPortfolioMetrics(
    $portfolioId: ID!
    $duration: Int!
    $radioStationId: ID!
  ) {
    advertiser {
      getPortfolioMetrics(
        portfolioId: $portfolioId
        duration: $duration
        radioStationId: $radioStationId
      ) {
        campaignName
        activeListiners
        spotPlays
        totalAmount
        numberOfStations
        numberOfSlots
        completed
        inProgress
        total
        broadcastSummary {
          id
          slotDate
          slotTime
          slotStreamedTime
          streamedStatus
          slotNumberOfAds
          slotNumberOfAdsPlayed
        }
      }
    }
  }
`;

export const GET_UNAPPROVED_BROADCASTERS = gql`
  query GetUnapprovedBroadcasters {
    admin {
      getUnapprovedBroadcasters {
        createdAt
        email
        group
        id
        isApproved
        isVerified
        radioStationName
      }
    }
  }
`;

export const GET_PORTFOLIO_SUBSCRIPTION_CHARTS = gql`
  subscription GetPortfolio($getPortfolioId: ID!) {
    getPortfolio(id: $getPortfolioId) {
      date
      count
    }
  }
`;

export const GET_BROADCASTER_AVAILABLE_SLOT = gql`
  query GetBroadcasterAvailableSlots($broadcasterId: ID!, $dates: [Date!]!) {
    guest {
      getBroadcasterAvailableSlots(
        broadcasterId: $broadcasterId
        dates: $dates
      ) {
        id
        broadcasterId
        startTime
        endTime
        isAvailable
        date
        Ann100WordsPrice
        Ann50WordsPrice
        Ann75WordsPrice
        Jingle15SecPrice
        Jingle30SecPrice
        Jingle45SecPrice
        Jingle60SecPrice
      }
    }
  }
`;
export const GET_RADIO_STATIONS_FOR_PORTFOLIO_METRICS = gql`
  query GetRadioStationsForPortfolio($portfolioId: ID!) {
    advertiser {
      getRadioStationsForPortfolio(portfolioId: $portfolioId) {
        radioStationId
        radioStationName
      }
    }
  }
`;
export const GET_ADMIN_DASHBOARD_STATS = gql`
  query GetAminDashboardStats {
    admin {
      getAminDashboardStats {
        numberOfAdvertiser
        numberOfRadioStation
        numberOfCampaign
        allCampaigns {
          id
          campaignName
          campaignStatus
        }
        totalValueOfCampaign
        pendingWithdraw
        activeCampaigns
        commissonEarned
      }
    }
  }
`;

export const GET_ALL_ADMIN_ADVERTISER_TRANSACTIONS = gql`
  query GetAllAdvertiserTransactions {
    admin {
      getAllAdvertiserTransactions {
        id
        advertiser
        amount
        withdrawalMethod
        date
        status
        description
      }
    }
  }
`;
export const GET_ALL_ADMIN_RADIO_VENDOR_TRANSACTIONS = gql`
  query GetAllRadioVendorTransactions {
    admin {
      getAllRadioVendorTransactions {
        id
        radioStation
        amount
        withdrawalMethod
        date
        status
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  subscription GetNotification {
    getNotification {
      id
      title
      message
      viewed
      createdAt
    }
  }
`;
export const GET_ALL_ADMIN_ADVERTISER = gql`
  query GetAllAdvertisers($input: getAllAdvertisersInput) {
    admin {
      getAllAdvertisers(input: $input) {
        data {
          id
          advertiser
          email
          phone
          date
        }
        activeCount
        inActiveCount
        activeCampaignCount
        inActiveCampaignCount
        allCount
        total
        page
        itemsPerPage
      }
    }
  }
`;

export const GET_ALL_ADMIN_ADVERTISER_BY_ID = gql`
  query GetAdvertiserById($advertiserId: ID!) {
    admin {
      getAdvertiserById(advertiserId: $advertiserId) {
        id
        name
        industry
        address
        phone
        country
        state
        city
        accountType
        referralCode
        accountStatus
        specialAdvertiserDiscount
      }
    }
  }
`;

export const GET_ALL_ORDERS = gql`
  query GetAllOrders {
    admin {
      getAllOrders {
        id
        invoice
        campaignName
        companyName
        industryName
        radioStation {
          id
          name
        }
        date
      }
    }
  }
`;

export const GET_RADIOSTATIONS_FOR_ADMIN = gql`
  query GetAllBroadcasters {
    admin {
      getAllBroadcasters {
        id
        radioStation
        email
        phone
        date
        status
        isEmailVerified
      }
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query GetOrderById($orderId: ID!) {
    admin {
      getOrderById(orderId: $orderId) {
        id
        startDate
        endDate
        campaignDocument {
          name
          url
        }
        radioStationDetails {
          id
          radioStation
          advertType
          duration
          language
          numberOfDays
          numberOfSpots
          totalAmount
          status
          paymentMethod
          bookedSlots {
            startDate
            endDate
            slots {
              advertType
              date
              quantity
              language
              startTime
              endTime
            }
            bookingDetails {
              totalNoOfDays
              totalNoOfSpots
              totalNoOfSlots
            }
          }
        }
      }
    }
  }
`;
export const ADMIN_GET_ALL_ADVERTISER_CAMPAIGNS_BY_ID = gql`
  query GetAllAdvertiserCampaignsById($userId: ID!) {
    admin {
      getAllAdvertiserCampaignsById(userId: $userId) {
        id
        invoice
        campaignName
        companyName
        industryName
        radioStation {
          id
          name
        }
        date
      }
    }
  }
`;
export const ADMIN_GET_ADVERTISER_CAMPAIGN_DETAILS_BY_ID = gql`
  query GetAdvertiserCampaignDetailsById($campaignId: ID!) {
    admin {
      getAdvertiserCampaignDetailsById(campaignId: $campaignId) {
        id
        startDate
        endDate
        campaignDocument {
          name
          url
        }
        radioStationDetails {
          id
          radioStation
          advertType
          duration
          language
          numberOfDays
          numberOfSpots
          totalAmount
          status
          paymentMethod
          bookedSlots {
            startDate
            endDate
            slots {
              advertType
              date
              quantity
              language
              startTime
              endTime
            }
            bookingDetails {
              totalNoOfDays
              totalNoOfSpots
              totalNoOfSlots
            }
          }
        }
      }
    }
  }
`;

export const GET_RADIOSTATION_PROFILE_DETAILS_FOR_ADMIN = gql`
  query GetBroadcasterDetailsById($broadcasterId: ID!) {
    admin {
      getBroadcasterDetailsById(broadcasterId: $broadcasterId) {
        profileDetails {
          radioStation
          nameOfPersonInCharge
          position
          contactNo
          address
          country
          state
          city
          zipcode
          radioCategory
          website
          digitalStreamUrl
        }
        bankInformation {
          bankName
          accountNumber
          accountName
        }
      }
    }
  }
`;

export const UPDATE_RADIOSTATION_DETAILS_ADMIN = gql`
  query RadioStationDetails($broadcasterId: ID!) {
    admin {
      getBroadcasterDetailsById(broadcasterId: $broadcasterId) {
        radioStationDetails {
          radioStation
          tagline
          state
          aboutUs
          logo
        }
        discountRates {
          startingPrice
          startingDiscount
          listOfDiscount {
            id
            amount
            discount
          }
        }
      }
    }
  }
`;

export const GET_BROADCASTERDETAILS_BY_ID = gql`
  query GetBroadcasterDetailsById($broadcasterId: ID!) {
    admin {
      getBroadcasterDetailsById(broadcasterId: $broadcasterId) {
        profileDetails {
          radioStation
          nameOfPersonInCharge
          position
          contactNo
          address
          country
          state
          city
          zipcode
          radioCategory
          website
          digitalStreamUrl
        }
        bankInformation {
          bankName
          accountNumber
          accountName
        }
        radioStationDetails {
          radioStation
          tagline
          state
          aboutUs
          logo
        }
        orders {
          id
          totalAmountSpent
          totalEarnings
          status
          date
        }
        programSchedule {
          id
          startTime
          endTime
          show
          sponsor
          status
          weekDay
          broadcasterId
        }
        reportOverview {
          sales
          pending
          commission
          totalEarnings
          campaigns
        }
        statement {
          transactionDate
          campaignId
          type
          sales
          commission
          earning
        }
        paymentHistroy {
          transactionDate
          amount
          status
          paymentMethod
        }
        discountRates {
          startingPrice
          startingDiscount
          listOfDiscount {
            id
            amount
            discount
          }
        }
        rates {
          id
          broadcasterId
          Jingle15SecPrice
          Jingle30SecPrice
          Jingle45SecPrice
          Jingle60SecPrice
          Ann50WordsPrice
          Ann75WordsPrice
          Ann100WordsPrice
          startTime
          endTime
        }
      }
    }
  }
`;

export const GET_BANKS = gql`
  query GetBanks {
    admin {
      getBanks {
        name
        code
      }
    }
  }
`;
export const GET_BANK_ACCOUNT_NAME = gql`
  query Admin($accountNumber: String, $bankCode: String) {
    admin {
      getAccountName(accountNumber: $accountNumber, bankCode: $bankCode)
    }
  }
`;
export const GET_SUPPORTED_CURRENCIES = gql`
  query GetSupportedCurrencies {
    guest {
      getSupportedCurrencies {
        country
        currency
      }
    }
  }
`;
export const GET_EXCHANGE_RATES = gql`
  query GetExchangeRates {
    admin {
      getExchangeRates {
        currency
        rate
      }
    }
  }
`;

export const GET_ADVERTISER_PROFILE = gql`
  query GetAdvertiserProfile {
    advertiser {
      getAdvertiserProfile {
        id
        email
        fullname
        industry
        address
        country
        state
        city
        phoneNumber
        accountType
        cacFileUrl
      }
    }
  }
`;
